const BASE_URL = 'https://api.orchestremontois.fr'
const TITLE = '• OM - Espace Musiciens'
const DSN_URL = 'https://268b11452311bd2a0ffb9176876a1441@o4508051935723520.ingest.de.sentry.io/4508051960102992'
const VERSION = require('../package.json').version

const MAINTENANCE = {
	date: new Date('2024-01-22').toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
	hours: '16h à 23h',
	active: false,
}

const RESPONSE_VALUES = [
	['Pas de réponse', 'grey.500', 'solar:notification-remove-broken'],
	['Participe', 'success.main', 'solar:check-circle-broken'],
	['Ne participe pas', 'error.main', 'solar:close-circle-broken'],
	['Disponible si nécessaire', 'info.main', 'solar:dialog-broken'],
	['Ne sais pas', 'warning.main', 'solar:question-circle-broken']
];

export { BASE_URL, TITLE, DSN_URL, VERSION, MAINTENANCE, RESPONSE_VALUES }